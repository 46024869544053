import { ANALISE_IMAGEM_LOAD, ANALISE_IMAGEM_UNLOAD } from "../../actions/Constants";

const INITIAL_STATE = { all: [] };

export default function analiseImagem(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ANALISE_IMAGEM_LOAD:
      return {
        ...state,
        all: action.payload ? action.payload : [],
      };
    case ANALISE_IMAGEM_UNLOAD:
      return INITIAL_STATE;
    default:
      return state;
  }
}
