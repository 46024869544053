import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, SubmissionError, change, untouch } from "redux-form";
import _ from "lodash";
import { Link } from "react-router";
import Title from "../../components/Layout/Title";
import { toastr } from "react-redux-toastr";

import MaskDate from "../../components/Util/MaskDate";
import { ANALISE_IMAGEM_LOAD, ANALISE_IMAGEM_UNLOAD } from "../../actions/Constants";

import api from "../../api/apiUtil";
import SelectField from "../../components/Fields/SelectField";
import FieldValidation from "../../components/Validation/FieldValidation";
import TextDatePickerField from "../../components/Fields/TextDatePickerField";
import TextField from "../../components/Fields/TextField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import TextareaField from "../../components/Fields/TextareaField";
import ReactTable from "react-table";
import { BeatLoader } from "react-spinners";
import fileDownload from "js-file-download";

class AnaliseImagem extends React.Component {
  constructor() {
    super();
    this.state = {
      pesquisando: false,
      detalhar: null,
    };
  }

  componentDidMount() {
    this.props.onListLoad(api.Configuracao.getImagensAnalisadas());
  }

  componentWillUnmount() {}

  formSubmit(values) {
    var props = this.props;
    Promise.resolve(api.Configuracao.getImagensAnalisadas(values.chassi, values.faixaScore, MaskDate.TO_DATABASE(values.dataValidacao)))
      .then((ret) => {
        this.props.onListLoad(ret);
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  detalhar(item) {
    var props = this.props;
    Promise.resolve(api.Configuracao.getImagemAnalisada(item.idImagem))
      .then((ret) => {
        this.props.dispatch(change("AnaliseImagemForm", "detFinanceira", ret[0].razaoSocial));
        this.props.dispatch(change("AnaliseImagemForm", "detContrato", ret[0].numero));
        this.props.dispatch(change("AnaliseImagemForm", "detChassi", ret[0].chassi));
        this.props.dispatch(change("AnaliseImagemForm", "detDataValidacao", MaskDate.TO_BR_DATETIME(ret[0].dataValidacao)));
        this.props.dispatch(change("AnaliseImagemForm", "detNomeArquivo", ret[0].nome));
        this.props.dispatch(change("AnaliseImagemForm", "detScore", ret[0].scoreValidacao));
        this.props.dispatch(change("AnaliseImagemForm", "detPalavrasPesquisadas", JSON.parse(ret[0].resultadoValidacao).keywords.join(", ")));
        this.props.dispatch(change("AnaliseImagemForm", "detPalavrasEncontradas", JSON.parse(ret[0].resultadoValidacao).keywordsFound.join(", ")));
        this.props.dispatch(change("AnaliseImagemForm", "detOcr", JSON.parse(ret[0].resultadoValidacao).textContent.trimStart()));
        this.setState({ detalhar: ret });
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  handleClickImage = () => (ev) => {
    ev.preventDefault();
    toastr.info("Aguarde", "Processando arquivo...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    Promise.resolve(api.Contracts.getImage(this.state.detalhar[0].idContrato))
      .then((response) => {
        toastr.removeByType("info");
        fileDownload(response, this.state.detalhar[0].nome);
      })
      .catch((e) => {
        console.log(e);
        toastr.removeByType("info");
      });
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Análise de imagens de contratos" />
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                {!this.state.detalhar ? (
                  <>
                    <div className="panel-heading">
                      <div className="panel-tools"></div>
                      Realizar Consulta
                    </div>
                    <div className="panel-body">
                      <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                        <div className="row">
                          <div className="col-md-3">
                            <Field name="chassi" label="Chassi" component={TextField} type="text" maxlength={17} />
                          </div>
                          <div className="col-md-2">
                            <Field
                              name="faixaScore"
                              label="Faixa de Score"
                              blank={true}
                              data={[
                                { id: "1", name: "Alto" },
                                { id: "2", name: "Médio" },
                                { id: "3", name: "Baixo" },
                              ]}
                              component={SelectField}
                              type="text"
                              valueField="id"
                              textField="name"
                            />
                          </div>
                          <div className="col-md-2">
                            <Field
                              name="dataValidacao"
                              label="Data de Validação"
                              validate={[FieldValidation.date]}
                              normalize={FieldNormalize.DATE}
                              placeholder="00/00/0000"
                              data={this.props.dataValidacao}
                              component={TextDatePickerField}
                              type="text"
                              inline={false}
                            />
                          </div>
                          <div className="col-lg-2 align-self-end form-group">
                            <button className="btn btn-info" type="submit" disabled={this.state.pesquisando}>
                              {this.state.pesquisando ? "Aguarde..." : "Filtrar"}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="hpanel">
                          <div className="panel-heading">Imagens Analisadas</div>
                          <div className="panel-body">
                            <div className="table-responsive">
                              <ReactTable
                                className="-striped -highlight"
                                defaultPageSize={20}
                                data={this.props.all}
                                loadingText="Carregando..."
                                noDataText="Nenhum Registro encontrado"
                                rowsText="linhas"
                                showPageSizeOptions={false}
                                minRows={1}
                                showPagination={true}
                                previousText="Anterior"
                                nextText="Próximo"
                                pageText="página"
                                ofText="de"
                                columns={[
                                  {
                                    Header: "Financeira",
                                    accessor: "razaoSocial",
                                    headerClassName: "font-extra-bold",
                                  },
                                  {
                                    Header: "Nr Contrato",
                                    accessor: "numero",
                                    className: "align-middle text-center",
                                    headerClassName: "font-extra-bold",
                                  },
                                  {
                                    Header: "Chassi",
                                    accessor: "chassi",
                                    className: "align-middle text-center",
                                    headerClassName: "font-extra-bold",
                                    width: 160,
                                  },
                                  {
                                    Header: "Data Validação",
                                    accessor: "rowData",
                                    className: "align-middle text-center",
                                    headerClassName: "font-extra-bold",
                                    width: 140,
                                    Cell: (rowData) => {
                                      return (
                                        <div>
                                          <span>{MaskDate.TO_BR_DATETIME(rowData.original.dataValidacao)}</span>
                                          <br />
                                        </div>
                                      );
                                    },
                                  },
                                  {
                                    Header: "Nome do Arquivo",
                                    accessor: "nome",
                                    className: "align-middle text-center",
                                    headerClassName: "font-extra-bold",
                                  },
                                  {
                                    Header: "Score",
                                    accessor: "scoreValidacao",
                                    className: "align-middle text-center",
                                    headerClassName: "font-extra-bold",
                                    width: 120,
                                    Cell: (row) => {
                                      const score = row.value;
                                      let bgColor = "";
                                      if (score >= 0.75) {
                                        bgColor = "#59c25e";
                                      } else if (score >= 0.5) {
                                        bgColor = "#e8e53c";
                                      } else {
                                        bgColor = "#ff5b5b";
                                      }
                                      return (
                                        <div
                                          style={{
                                            backgroundColor: bgColor,
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            color: "black",
                                          }}
                                        >
                                          {score}
                                        </div>
                                      );
                                    },
                                  },

                                  {
                                    Header: "Ação",
                                    className: "align-middle text-center",
                                    headerClassName: "font-extra-bold",
                                    width: 190,
                                    Cell: (row) => (
                                      <div className="btn-group">
                                        <Link className="btn btn-sm btn-default" onClick={() => this.detalhar(row.original)}>
                                          <i className="fas fa-search" /> Detalhar
                                        </Link>
                                      </div>
                                    ),
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="panel-body">
                    <form className="form-horizontal">
                      <div className="row">
                        <div className="col-md-6">
                          <Field name="detFinanceira" label="Financeira" component={TextField} type="text" disabled />
                        </div>
                        <div className="col-md-2">
                          <Field name="detContrato" label="Nr Contrato" component={TextField} type="text" disabled />
                        </div>
                        <div className="col-md-2">
                          <Field name="detChassi" label="Chassi" component={TextField} type="text" disabled />
                        </div>
                        <div className="col-md-2">
                          <Field name="detDataValidacao" label="Data Validação" component={TextField} type="text" disabled />
                        </div>
                        <div className="col-md-3">
                          <Field name="detNomeArquivo" label="Nome do Arquivo" component={TextField} type="text" disabled />
                        </div>
                        <div className="col-md-1">
                          <Field name="detScore" label="Score" component={TextField} type="text" disabled />
                        </div>
                        <div className="col-md-4">
                          <Field name="detPalavrasPesquisadas" label="Palavras Pesquisadas" component={TextareaField} rows={2} type="text" disabled />
                        </div>
                        <div className="col-md-4">
                          <Field name="detPalavrasEncontradas" label="Palavras Encontradas" component={TextareaField} rows={2} type="text" disabled />
                        </div>
                        <div className="col-md-12">
                          <Field name="detOcr" label="Texto Extraído do Documento" component={TextareaField} type="text" rows={10} disabled />
                        </div>
                        <div className="col-lg-12 align-self-end form-group text-right">
                          <button className="btn btn-info btn-sm" onClick={this.handleClickImage()}>
                            <i className="fas fa-image"></i> Baixar Imagem{" "}
                          </button>
                          <button className="btn btn-default btn-sm" onClick={() => this.setState({ detalhar: null })}>
                            Voltar
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onListLoad: (payload) => dispatch({ type: ANALISE_IMAGEM_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: ANALISE_IMAGEM_UNLOAD, payload }),
});

const selector = formValueSelector("AnaliseImagemForm");
const mapStateToProps = (state) => ({
  ...state.analiseImagem,
  dataValidacao: selector(state, "dataValidacao"),
});

const form = reduxForm({
  form: "AnaliseImagemForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(AnaliseImagem));
